import React from 'react'
import I18n from 'i18n/index.js.erb'

export default function SignUp() {
  return (
    <div>
      <p>{I18n.t('hello')}</p>
    </div>
  )
}
