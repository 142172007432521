import { createSlice, createSelector } from '@reduxjs/toolkit'
import get from 'lodash/get'

import type { I18nTimeFormats } from '@placementsapp/reports/interface'

export const slice = createSlice({
  name: 'session',
  initialState: {},
  reducers: {},
})

// export const {} = slice.actions // Unexpected empty object pattern. (no-empty-pattern)

export const selectCurrentOrg = (state) => state.session?.current_org

// Try NOT to be lulled into the anti-pattern of direct `session` selection outside of `sessionSlice.ts`.
const selectSession = (state) => state.session
const select_session = selectSession

export const selectCurrentUserId = (state) =>
  get(state, 'session.current_user.id', null)

export const selectPaymentOption = (state) =>
  get(state, 'session.current_org.payment_option', 'credit_card')

export const reports_feature_enabled_selector = createSelector(
  [select_session],
  (session): boolean => !!session?.current_org?.options?.reports_feature_enabled
)

export const is_dev_selector = createSelector(
  [select_session],
  (session): boolean => 'development' === session?.env
)

export const i18n_time_formats_selector = createSelector(
  [select_session],
  (session): I18nTimeFormats => session?.i18n_time_formats || {}
)

export const locale_selector = createSelector(
  [select_session],
  (session): string => session?.current_org?.locale || 'en'
)

export const order_line_item_custom_fields_options_selector = createSelector(
  [select_session],
  (session) =>
    session?.current_publisher_org?.custom_fields?.order_line_item?.active || []
)

export const order_custom_fields_options_selector = createSelector(
  [select_session],
  (session) =>
    session?.current_publisher_org?.custom_fields?.order?.active || []
)

export const product_media_types_selector = createSelector(
  [select_session],
  (session) => session?.current_publisher_org?.product_media_types || {}
)

export const custom_support_url_selector = createSelector(
  [select_session],
  (session): null | string =>
    session?.current_publisher_org?.custom_support_url || null
)

export const current_org_name_selector = createSelector(
  [select_session],
  (session): null | string => session?.current_org?.name || null
)

export const terms_and_conditions_selector = createSelector(
  [select_session],
  (session): string => session.current_publisher_org?.terms_conditions || ''
)

export const current_org_payment_option_selector = createSelector(
  [select_session],
  (session) => session?.current_org?.payment_option
)

export const current_org_billing_source_selector = createSelector(
  [select_session],
  (session) => session?.current_org?.billing_source
)

export const current_publisher_org_display_logo_selector = createSelector(
  [select_session],
  (session): null | string =>
    session?.current_publisher_org?.display_logo || null
)

export const current_publisher_org_display_name_selector = createSelector(
  [select_session],
  (session): null | string =>
    session?.current_publisher_org?.display_name || null
)

export const current_user_first_name_selector = createSelector(
  [select_session],
  (session): null | string => session?.current_user?.first_name || null
)

export const current_user_last_name_selector = createSelector(
  [select_session],
  (session): null | string => session?.current_user?.last_name || null
)

export const current_user_avatar_url_selector = createSelector(
  [select_session],
  (session): null | string => session?.current_user?.avatar_url || null
)

export const stripe_data_publishable_key_selector = createSelector(
  [select_session],
  (session) => session?.stripe_data?.publishable_key
)

export const stripe_data_account_id_selector = createSelector(
  [select_session],
  (session) => session?.stripe_data?.account_id
)

export const approval_statuses_selector = createSelector(
  [select_session],
  (session) => session?.approval_statuses
)

export const delivery_statuses_selector = createSelector(
  [select_session],
  (session) => session?.delivery_statuses
)

export default slice.reducer
