import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Layout, Col, Row, notification, List } from 'antd'
import { WarningOutlined } from '@ant-design/icons'

import * as sessionSlice from '@placementsapp/jslibs/store/sessionSlice'
import {
  selectNotifications,
  removeNotification,
} from '@placementsapp/jslibs/store/notificationsSlice'
import UserMenu from '@placementsapp/uxlibs/Navbar/components/UserMenu'

const { Header } = Layout

const openNotification = (data, dispatch) => {
  const errors = (
    <>
      {Object.entries(data.errors || {}).map(([key, value]) => {
        return (
          <List key={key}>
            <List.Item>
              <List.Item.Meta
                avatar={<WarningOutlined />}
                title={`${key}:`}
                description={value}
              />
            </List.Item>
          </List>
        )
      })}
    </>
  )
  notification[data.type]({
    key: data.id,
    top: 50,
    duration: data.duration !== undefined ? data.duration : 0,
    message: <h3>{data.msg}</h3>,
    description: errors,
    onClose: () => dispatch(removeNotification(data)),
  })
}

export default function Navbar() {
  const dispatch = useDispatch()

  const current_publisher_org_display_logo = useSelector(sessionSlice.current_publisher_org_display_logo_selector)
  const current_publisher_org_display_name = useSelector(sessionSlice.current_publisher_org_display_name_selector)
  const current_user_first_name = useSelector(sessionSlice.current_user_first_name_selector)
  const current_user_last_name = useSelector(sessionSlice.current_user_last_name_selector)
  const current_user_avatar_url = useSelector(sessionSlice.current_user_avatar_url_selector)
  const notifications = useSelector(selectNotifications)

  useEffect(() => {
    notifications.forEach((data) => openNotification(data, dispatch))
  }, [notifications])

  return (
    <Header className={'uxlibs-navbar'}>
      <Row justify="space-between">
        <Col>
          <Row justify={'start'} gutter={16} wrap={false}>
            <Col>
              <a href='/'>
                <img
                  className="current_org_logo"
                  src={current_publisher_org_display_logo || '/images/placementsio_logo_white.png'}
                  alt="logo"
                />
              </a>
            </Col>
            <Col>
              <a href='/'>
                <h3 className="publisher-org-display-name">
                  {current_publisher_org_display_name || ''}
                </h3>
              </a>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row justify={'end'} gutter={16} wrap={false}>
            <Col>
              {current_user_first_name && (
                <UserMenu {...{current_user_first_name, current_user_last_name, current_user_avatar_url}} />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  )
}
