import React from 'react'
import I18n from 'i18n/index.js.erb'
import { Form, Input, Button, Row } from 'antd'

const onFinish = (values) => {
  document.getElementById('user_email').value = values['email']
  document.getElementById('user_password').value = values['password']
  document.getElementById('new_user').submit()
}

export default function SignIn() {
  return (
    <Form
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: I18n.t('missing_email_label'),
          },
        ]}
      >
        <Input placeholder={I18n.t('email_input_label')} />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: I18n.t('missing_password_label'),
          },
        ]}
        style={{ marginBottom: 0 }}
      >
        <Input.Password placeholder={I18n.t('password_input_label')} />
      </Form.Item>

      <Form.Item>
        <Row align="end">
          <a href="/users/password/new">{I18n.t('reset_password_label')}</a>
        </Row>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="form_submit_btn">
          {I18n.t('sign_in_btn_label')}
        </Button>
      </Form.Item>
    </Form>
  )
}
