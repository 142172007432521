import React from 'react'
import { Avatar, Dropdown, Menu, Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons'

export default ({ current_user_first_name, current_user_last_name, current_user_avatar_url }) => {
  const userMenu = () => {
    return (
      <Menu>
        <Menu.Item key={'profile'} icon={<UserOutlined />}>
          <Link to={'/profile'}>Profile</Link>
        </Menu.Item>
        <Menu.Item key={'settings'} icon={<SettingOutlined />}>
          <Link to={'/settings'}>Settings</Link>
        </Menu.Item>
        <Menu.Item key={'logout'} icon={<LogoutOutlined />}>
          <a href={'/users/sign_out'}>Log out</a>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <Dropdown
      className={'user-dropdown-menu'}
      overlay={userMenu}
      placement="bottomRight"
    >
      <Row gutter={16} style={{ cursor: 'pointer' }}>
        <Col>
          <Avatar icon={<UserOutlined />} src={current_user_avatar_url} />
        </Col>
        <Col>
          <div className="current-user-label">{`${current_user_first_name} ${current_user_last_name}`}</div>
        </Col>
      </Row>
    </Dropdown>
  )
}
